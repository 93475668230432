const { getOpenAxios, getJwtAxios } = require("../utils/apis")

const axios = getOpenAxios();

export const loginApi = async (data) =>{
    return await axios.post("/auth/login",data)
}

export const apiGetJwtToken = async (data) =>{
    return await axios.post("/auth/jwt-token",data)
}
 
