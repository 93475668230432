import axios from 'axios';

const API_URL = 'https://liveapi.fastvpp.vehicleplannerplus.com'; // 'https://api.fastuat.vehicleplannerplus.com'; //'http://localhost:3030'; 'http://fastuat.vehicleplannerplus.com:3030'
const openAxios = axios.create({
	baseURL: API_URL,
});

const tokenAxios = axios.create({
	baseURL: API_URL,
});

export const getOpenAxios = () => {
	openAxios.interceptors.request.use( req =>{
		req.data = {...req.data, token: localStorage.getItem("sec-token")}
		return req;
	})
	openAxios.interceptors.response.use(
		(response) => {
			if (response.headers.hasOwnProperty('token')) {
				localStorage.setItem('sec-token', response.headers.token);
			}
			return response;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	return openAxios;
};

export const getJwtAxios = () => {
	return tokenAxios;
};
