import { useSelector } from 'react-redux'
import HomeNav from '../Nav/HomeNav'
import Footer from './Footer'
import InsightBuilder from './InsightBuilder'
import ResultTable from './ResultTable'
import { useEffect, useState } from 'react'
import Login from './Login'
import cookie from 'react-cookies'
import appData from '../utils/appData'
import { apiGetJwtToken } from '../apis/auth'

const Home = () => {
    
    let userIdCookie = cookie.load('user_id')
    // console.log(userIdCookie)
    if(userIdCookie != undefined){
        localStorage.setItem('userId', userIdCookie)
    }else{
        window.location = appData.VPP_DOMAIN
    }

    
        //cookie.set('userId', 537)

    const [features, setFeatures] = useState([])
    const [insight, setInsight] = useState("")
    const [vehicles, setVehicles] = useState([])

    let localObj = localStorage.getItem("loginKey")

    const [isLoggedIn, setIsLoggedIn] = useState(true)

    useEffect( () =>{

        const getJwtToken = async() =>{
            let response = await apiGetJwtToken({userId: cookie.load('user_id'), userName: cookie.load('user_name')})
            if(response.data.result == "success"){
                localStorage.setItem('sec-token', response.data.token)
            }
        }
        getJwtToken()
    },[])
    
    return(
        <div >
            <HomeNav isLoggedIn={isLoggedIn} />
            <div className='container-fluid sbd-bg-light'>
                
                <InsightBuilder homePageUpdateFeatures={setFeatures} homePageInsight={setInsight} homePageVehicles={setVehicles} />
                
                <Footer />
            </div>
        </div>
    )
}
 
export default Home

/*


ssh -i "Fast VPP - Dev.pem" ubuntu@ec2-3-14-201-61.us-east-2.compute.amazonaws.com

ssh -i "C:\Users\user\Videos\Fast VPP - Dev.pem" ubuntu@3.14.201.61

*/


